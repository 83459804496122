<template>
  <v-container v-if="!loading">
    <v-row class="mt-3">
      <v-col cols="10">
        <h3>{{ isEditable ? 'Edit Carousel' : 'Add New Carousel' }}</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-text-field
          label="Title"
          v-model="carousel.title"
          autofocus
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" md="4">
        <v-combobox
          v-model="carousel.carousel_type"
          :search-input.sync="search"
          :items="cTypes"
          label="Carousel Type"
          :error-messages="validType ? '' : 'This type already exists.'"
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{ search }}</strong
                  >". Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="8" sm="6" lg="4">
        <v-row align="center">
          <v-col cols="4" sm="4">
            <v-switch v-model="carousel.visible" label="Visible"></v-switch>
          </v-col>
          <v-col cols="4" sm="4">
            <v-switch
              v-model="carousel.dismissible"
              label="Dismissible"
            ></v-switch>
          </v-col>
          <v-col cols="4" sm="4">
            <v-switch
              v-model="carousel.can_subscribe"
              label="Subscribe"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn color="success" :disabled="!validType" @click.stop="save">{{
          isEditable ? 'Update' : 'Save'
        }}</v-btn>
        <v-btn class="ml-4" to="/admin/carousel/list" text>Back</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      loading: 'loading',
      carouselList: 'carouselList'
    })
  },
  watch: {
    model() {
      this.$nextTick(() => this.model.pop())
    },
    carousel: {
      deep: true,
      handler() {
        if (this.isEditable) {
          const unavaTypes = this.carouselList
            .map(c => c.carousel_type)
            .filter(c => c !== this.prevType)
          this.validType = !unavaTypes.includes(this.carousel.carousel_type)
        } else {
          this.validType = !this.carouselList
            .map(c => c.carousel_type)
            .includes(this.carousel.carousel_type)
        }
      }
    }
  },
  data() {
    return {
      cTypes: [
        'product_content',
        'favorites_restaurants',
        'available_now_producers',
        'restaurants',
        'new_products',
        'popular_products',
        'last_calls'
      ],
      validType: true,
      search: null,
      isEditable: false,
      prevType: '',
      carousel: {
        id: null,
        title: '',
        carousel_type: '',
        order: -1,
        visible: false,
        dismissible: false,
        can_subscribe: false
      }
    }
  },
  async mounted() {
    await this.getCarouselList()
    if (this.$route.params.id) {
      this.$store.dispatch('getCarousel', this.$route.params.id).then(c => {
        this.prevType = c.carousel_type
        this.carousel = c
        this.isEditable = true
      })
    } else {
      this.isEditable = false
      this.carousel.order = this.list.length
        ? Math.max(...this.list.map(c => c.order)) + 1
        : 0
    }
  },
  methods: {
    async getCarouselList() {
      await this.$store.dispatch('getCarousels')
      this.list = this.carouselList
    },
    save() {
      this.$store.dispatch('saveCarousel', this.carousel)

      this.$store.dispatch('setMessage', {
        title: 'Save',
        body: this.carousel.id ? 'Carousel updated.' : 'New carousel added.'
      })
    }
  }
}
</script>
